import moment from 'moment'
import ReactDOM from 'react-dom'
import axios from 'axios'

import CanvasState from '../state/canvasState'
import {GAME_UPDATE_BY_ID, GAME_UPDATE_TIMER, GAME_UPDATE_CANVASDATA,  
    GAME_UPDATE_OBJECTS, GAME_UPDATE_TPP, GAME_UPDATE_LABEL_AND_CATEGORY, 
    GAME_UPDATE_CATEGORYCOLOR, GAME_UPDATE_LIBRARYOBJECTS, GAME_UPDATE_RULEBOOK, 
    GAME_EMAIL, GAME_CLEAR_PLAYER_UNRECEIVED, GAME_UPDATE_NOTIFICATION_LOG,
    GAME_UPDATE_PARTICIPANTS
} from '../../../api/graphql-mutation'
import EE from '../../../api/eventemitter'
import socket from '../../../api/socket'
import {clientURI} from '../../../api/clientURI'
import {getUserId, getUsername,getPlayerFromPlayerList, isLeader} from '../../../utility/function'
import {getScore, setStep, setRoles, returnPermissions, iterateStep, loadStep} from './playerListHelper'
import config from '../../../config/backend'

socket.on("Event Manager Data", (data) =>{
    const {userIds, playerList, drawOps, latestObject, gid} = data

    EventManager.drawOps = drawOps
    EventManager.playerList = playerList
    EventManager.userIds = userIds
    EventManager.latestObject = latestObject
    EventManager.gid = gid
})

export const EventManager = {
    eventStruct: {},
    userIds: null,
    playerList: null,
    drawOps: null,
    latestObject: null,
    stepEvent: false,

    initEvent(userIds, playerList, drawOps, latestObject, gid, actionEventCard = null, jamdetails = null){
        //console.log(actionEventCard)
        //console.log("ACTION")
        socket.emit("Event Manager Data", {
            userIds: userIds, 
            playerList: playerList, 
            drawOps: drawOps, 
            latestObject: latestObject, 
            gid: gid
        })

        let startingEventName = this.eventStruct["startingEvent"];
        let eventNode = this.eventStruct[startingEventName]
        this.userIds = userIds
        this.playerList = playerList
        this.drawOps = drawOps
        this.latestObject = latestObject
        this.gid = gid
        this.actionEventCard = actionEventCard
        this.jamdetails = jamdetails

        // console.log(this.eventStruct,eventNode)
        this.eventPathWay(eventNode)
    },

    initStepEvent(userIds, playerList, drawOps, latestObject, gid){
        //console.log("STEP")
        this.stepEvent = true;
        socket.emit("Event Manager Data", {
            userIds: userIds, 
            playerList: playerList, 
            drawOps: drawOps, 
            latestObject: latestObject, 
            gid: gid
        })

        let startingEventName = this.eventStruct["startingEvent"]
        let eventNode = this.eventStruct[startingEventName]
        this.userIds = userIds
        this.playerList = playerList
        this.drawOps = drawOps
        this.latestObject = latestObject
        this.gid = gid

        this.eventPathWay(eventNode)

        //this.stepEvent = false;

    },

    eventPathWay(eventNode){

        //console.log(eventNode)
        // console.log(this.actionEventCard)

        switch(eventNode?.eventType){
            case "Check Card in Hand":
                this.checkCardInHand(eventNode) 
                break
            case "Vote on Card":
                this.voteCard(eventNode) 
                break
            case "Shift To": 
                this.shiftTo(eventNode)
                break
            case "Draw Card":
                this.drawOperation(eventNode)
                break
            case "Add Point":
                break
            case "Clear Card":
                EE.emit("Clear Card", {cardType: eventNode.target})
                if(eventNode["onComplete"]?.["Next Event"]){
                    let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                    this.eventPathWay(nextEvent)
                }
                break
            case "Notification":
                this.notificationOps(eventNode)
                break
                // EE.emit("Notification",{notification: eventNode.notification, target: eventNode.target}) // to canvas
            case "Timer":
                if(this.stepEvent){ // if is a event run by step 
                    this.stepEvent = false;
                    let uid = getUserId();
                        if(uid === this.playerList[0].uid){
                            // run jamtimer
                            EE.emit("Jam Timer Event",{eventNode})
                        }
                        if(eventNode["onComplete"].hasOwnProperty("Next Event")){
                            let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                            this.eventPathWay(nextEvent)
                        }
                    
                }
                break
            case "Email":
                this.emailOps(eventNode)
                break
            default:     
                break
        
        }
    },
  
    checkCardInHand(eventNode){
        axios({
            baseURL: `${config.backend.uri}`,
            url:'graphql',
            method: "POST",
            header:{
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data:{ 
                query:`
                    query{
                        gameById(_id:"${this.gid}"){
                        inhand
                        }
                    }
                `
            }
        }).then((response) => {
            const inhand = response.data.data.gameById.inhand
            let checkID = ""
            let playerList = this.drawOps.playerList;
            let playerTurn = this.drawOps.playerTurn;
            switch(eventNode.check){
                case "Current In Turn": 
                    checkID = getPlayerFromPlayerList(playerList,playerTurn, 0) 
                    break
                case "Next In Turn": 
                    checkID = getPlayerFromPlayerList(playerList,playerTurn, 1)
                    break
                case "Previous In Turn":
                    checkID = getPlayerFromPlayerList(playerList,playerTurn, -1)
                    break
                case "Originator": 
                    checkID = this.userIds[0]
                    break
                case "Jam Host":
                    checkID = this.drawOps.playerList[0].uid // for leader id
                    break
                default: break
            }

            const targetInHand = inhand.find(obj => obj.uid === checkID)
            let cards = {}

            for(let i = 0; i < targetInHand.objects.length; i++){
                let card = targetInHand.objects[i]
                let cardType = card.type
                let cardCategory = card.category
                
                if(!cards.hasOwnProperty(cardType)){ 
                    cards[cardType] = {} 
                }
    
                if(!cards[cardType].hasOwnProperty(cardCategory)){
                    cards[cardType][cardCategory] = 1
                }
                else{
                    ++cards[cardType][cardCategory]
                }   
            }
    
            /* 
            Example structure
            cards = {
                blank: 
                    blank: 1
                
                predefined:
                    story: 4
                    ending: 1
            }
            */ 

            let playerRole = eventNode.check
            let eventRules = eventNode[playerRole]
            let cardCategory = eventRules.card
            let nextEvent = null
            //If the player even has the card
            if(cards && cards["predefined"]?.[cardCategory]){
                let cardAmount = cards["predefined"][cardCategory]
                //Compare the amount of cards in the hand based on the template's rule
                if(this.compOperation(eventRules["operation"], cardAmount, eventRules["amount"])){
                    if(eventNode["onSuccess"]?.["Next Event"]){
                        nextEvent = this.eventStruct[eventNode["onSuccess"]["Next Event"]]
                        this.eventPathWay(nextEvent)
                    }
                }
                else{
                    if(eventNode["onFail"]?.["Next Event"]){
                        nextEvent = this.eventStruct[eventNode["onFail"]["Next Event"]]
                        this.eventPathWay(nextEvent)
                    }
                }
            }
            else{
                if(eventNode["onFail"]?.["Next Event"]){
                    nextEvent = this.eventStruct[eventNode["onFail"]["Next Event"]]
                    this.eventPathWay(nextEvent)
                }
            }

            let completeEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
            this.eventPathWay(completeEvent)
        })
    },

    voteCard(eventNode){
        //Fetch the latest card from CanvasState
        let voteEvent = eventNode
        let condition = null
        
        if(voteEvent["voteCheck"] === "reference"){
            condition = voteEvent["voteReference"]
        }
        else if(voteEvent["voteCheck"] === "amount"){
            condition = voteEvent["voteAmount"]
        }
        //More condition etc
        // Emitted to gameCanvas & gameVote
        EE.emit("Call for Vote", {
            playerNames: "",
            title: "",
            latestObject: this.latestObject,
            userIds: this.userIds,
            playerList: this.playerList,
            permissions: voteEvent.permissions,
            operation: voteEvent.operation,
            condition: condition,
            successEvent: this.eventStruct[voteEvent["onSuccess"]["Next Event"]],
            failEvent: this.eventStruct[voteEvent["onFail"]["Next Event"]],
            nextEvent: this.eventStruct[voteEvent["onComplete"]["Next Event"]]
        })
        
    },

    shiftTo(eventNode){
        const {shiftAction, shiftTo} = eventNode.target
        EE.emit("Event Script Shift To", {shiftAction: shiftAction, shiftTo: shiftTo})

        if(eventNode["onComplete"]?.["Next Event"]){
            let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
            this.eventPathWay(nextEvent)
        }
    }, 

    compOperation(operator, condition, reference){
        switch(operator){
            case "<":
                return condition < reference
            case "<=":
                return condition <= reference
            case "=":
                return condition == reference
            case ">=":
                return condition >= reference
            case ">":
                return condition > reference
            default:
                break
        }
    },

    drawOperation(eventNode){
        let gid = this.gid
        console.log(eventNode)
        if(this.stepEvent){ // if is a event run by step 
            //console.log("DO STEP",this.stepEvent)
            this.stepEvent = false;
            let uid = getUserId();
            //let gid = this.gid;
            if(uid === this.drawOps.playerList[0].uid){
                
                if(eventNode.check === "All Players"){
                    EE.emit("Game Draw To Many",{script: eventNode.script})
                }else{
                    let playerList = this.drawOps.playerList;
                    let playerTurn = this.drawOps.playerTurn;
                    let penalizeId = ""
        
                    switch(eventNode.target){
                        case "Current In-Turn": 
                            penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 0) 
                            break
                        case "Next In-Turn": 
                            penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 1)
                            break
                        case "Previous In-Turn":
                            penalizeId = getPlayerFromPlayerList(playerList,playerTurn, -1)
                            break
                        case "Event Trigger Origin": 
                            penalizeId = this.userIds[0]
                            break
                        case "Jam Host":
                            penalizeId = this.drawOps.playerList[0].uid // for leader id
                            break
                        case "Card Owner":
                            penalizeId = this.actionEventCard.origin.id
                            break
                        default: break
                    }
        
                    socket.emit("Game Custom Draw Action", {event: eventNode.script, penalizeId: penalizeId, status: "success",gid })
                }
        
                if(eventNode["onComplete"].hasOwnProperty("Next Event")){
                    let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                    this.eventPathWay(nextEvent)
                }

            }
        }else{
            if(eventNode.check === "All Players"){
                EE.emit("Game Draw To Many",{script: eventNode.script})
            }else{
                let playerList = this.drawOps.playerList;
                let playerTurn = this.drawOps.playerTurn;
                let penalizeId = ""
    
                switch(eventNode.target){
                    case "Current In-Turn": 
                        penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 0) 
                        break
                    case "Next In-Turn": 
                        penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 1)
                        break
                    case "Previous In-Turn":
                        penalizeId = getPlayerFromPlayerList(playerList,playerTurn, -1)
                        break
                    case "Event Trigger Origin": 
                        penalizeId = this.userIds[0]
                        break
                    case "Jam Host":
                        penalizeId = this.drawOps.playerList[0].uid // for leader id
                        break
                    case "Card Owner":
                        penalizeId = this.actionEventCard.origin.id
                        break
                    default: break
                }
                socket.emit("Game Custom Draw Action", {event: eventNode.script, penalizeId: penalizeId, status: "success",gid })
            }
    
            if(eventNode["onComplete"].hasOwnProperty("Next Event")){
                let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                this.eventPathWay(nextEvent)
            }
            
        }
        

        // if(eventNode.check === "All Players"){
        //     EE.emit("Game Draw To Many",{script: eventNode.script})
        // }else{
        //     let playerList = this.drawOps.playerList;
        //     let playerTurn = this.drawOps.playerTurn;
        //     let penalizeId = ""

        //     switch(eventNode.target){
        //         case "Current In-Turn": 
        //             penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 0) 
        //             break
        //         case "Next In-Turn": 
        //             penalizeId = getPlayerFromPlayerList(playerList,playerTurn, 1)
        //             break
        //         case "Previous In-Turn":
        //             penalizeId = getPlayerFromPlayerList(playerList,playerTurn, -1)
        //             break
        //         case "Event Trigger Origin": 
        //             penalizeId = this.userIds[0]
        //             break
        //         case "Jam Host":
        //             penalizeId = this.drawOps.playerList[0].uid // for leader id
        //             break
        //         default: break
        //     }

        //     socket.emit("Game Custom Draw Action", {event: eventNode.script, penalizeId: penalizeId, status: "success" })
        // }

        // if(eventNode["onComplete"].hasOwnProperty("Next Event")){
        //     let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
        //     this.eventPathWay(nextEvent)
        // }
    },

    emailOps(eventNode){
        let emailTarget = null
        let playerList = this.drawOps.playerList;
        let playerTurn = this.drawOps.playerTurn;
        let sender = getUsername();
        let title = this.jamdetails.jamtitle;
        let jamlink = this.jamdetails.jamlink;
        let content = eventNode.content
        let recipients = []

        switch(eventNode.target){
            case "All Players":
                emailTarget = "All"
                break
            case "Current In-Turn": 
                emailTarget = getPlayerFromPlayerList(playerList,playerTurn, 0) 
                break
            case "Next In-Turn": 
                emailTarget = getPlayerFromPlayerList(playerList,playerTurn, 1)
                break
            case "Previous In-Turn":
                emailTarget = getPlayerFromPlayerList(playerList,playerTurn, -1)
                break
            case "Event Trigger Origin": 
                emailTarget = this.userIds[0]
                break
            case "Jam Host":
                emailTarget = this.drawOps.playerList[0].uid // for leader id
                break
            case "Card Owner":
                emailTarget = this.actionEventCard.origin.id // need to have a designated card state before firing event
                break
            default: break
        }

        if(emailTarget === "All"){
            for(let i = 0; i<playerList.length;i++){
                recipients.push(playerList[i].email)
            }
        }else{
            let receiver = playerList.find((p)=>{return p.uid === emailTarget})
            recipients.push(receiver.email)
        }

        clientURI.mutate({
            mutation: GAME_EMAIL,
            variables: {
                sender,
                recipients,
                title,
                jamlink,
                content,
            }
        })

        if(eventNode["onComplete"].hasOwnProperty("Next Event")){
            let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
            this.eventPathWay(nextEvent)
        }
        
    },

    notificationOps(eventNode){
        //console.log(eventNode)
        let notifyTarget = null
        let playerList = this.drawOps.playerList;
        let playerTurn = this.drawOps.playerTurn;
        let gid = this.gid
        
        switch(eventNode.target){
            case "All Players":
                notifyTarget = "All"
                break
            case "Current In-Turn": 
                notifyTarget = getPlayerFromPlayerList(playerList,playerTurn, 0) 
                break
            case "Next In-Turn": 
                notifyTarget = getPlayerFromPlayerList(playerList,playerTurn, 1)
                break
            case "Previous In-Turn":
                notifyTarget = getPlayerFromPlayerList(playerList,playerTurn, -1)
                break
            case "Event Trigger Origin": 
                notifyTarget = this.userIds[0]
                break
            case "Jam Host":
                notifyTarget = this.drawOps.playerList[0].uid // for leader id
                break
            case "Card Owner":
                notifyTarget = this.actionEventCard.origin.id // need to have a designated card state before firing event
                break
            default: break
        }

        if(this.stepEvent){ // if is a event run by step 
            this.stepEvent = false;
            let uid = getUserId();
                socket.emit("Game Notification Action",{notification: eventNode.notification, notifyTarget,gid})
                if(eventNode["onComplete"].hasOwnProperty("Next Event")){
                    let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                    this.eventPathWay(nextEvent)
                }
            
        }else{
            socket.emit("Game Notification Action",{notification: eventNode.notification, notifyTarget,gid})
            if(eventNode["onComplete"].hasOwnProperty("Next Event")){
                let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
                this.eventPathWay(nextEvent)
            }
        }

        
        // if(eventNode["onComplete"].hasOwnProperty("Next Event")){
        //     let nextEvent = this.eventStruct[eventNode["onComplete"]["Next Event"]]
        //     this.eventPathWay(nextEvent)
        // }
    }
}

export const CanvasUtil = {
    getPlayerTurn(playerTurn){
        // if(this.props.automation === "3"){
        //     let uid = getUserId();
        //     let index = this.props.participants.findIndex((n)=>{return n.uid === uid})
        //     return index + 1
        // }
        //else{
        //     return n
        // }
        
        return playerTurn;
    },

    getAutomationTitle(automationIndex){
        switch(automationIndex){
            case "1": return "GAME JAM"
            case "2": return "STORY JAM"
            case "3": return "OPEN JAM"
            default: return "OPEN JAM"
        }
    },

    checkObjDrop(clientCoordinates, canvasData, cardAddition, draggedObject, spectateInfo){
        const {clientX, clientY} = clientCoordinates
        const {x, y, scale, latestID} = canvasData
        const uid = getUserId()
        
        if(cardAddition && draggedObject && (cardAddition.hasOwnProperty(draggedObject.data.category) || cardAddition.hasOwnProperty("Any"))){
            let obj = {
                objId: `${uid}-${latestID}`,
                attachment: null,
                interrupt: null,
                origin: {
                    username: spectateInfo ? spectateInfo.spectator_name : getUsername(),
                    id: spectateInfo ? spectateInfo.spectator_id : uid,
                    email: spectateInfo ? spectateInfo.spectator_email : null,
                },
                flip: null,
                edited:false, 
                pin:false,
                vote: false,
                time: moment.utc(),
                type: null,
                status: null,
                imgUrl: null, 
                label: null,
                category: null,
                content: null,
                pos: {
                    x: (-x + clientX) * (1/scale),
                    y: (-y + (clientY-112)) * (1/scale)
                },
                anchorEl: null,
                curlibID: draggedObject.data.libID,
                pairDir: null,
                paired: {
                  left: null,
                  right: null,
                  up: null,
                  down: null
                },
            }
            
            // console.log(draggedObject)
    
            switch(draggedObject.type){
                    case "predefined":
                    //Since we can now directly get the content of the cards via data.value when dragging
                    obj.content =  draggedObject.data.content
                    obj.type = "predefined"
                    obj.status = null
                    obj.flip = draggedObject.data.flip
                    obj.label = draggedObject.data.label
                    obj.category = draggedObject.data.category
                    obj.imgUrl = draggedObject.data.imgUrl
                    if(draggedObject?.from === "InHand"){
                        EE.emit("Game Remove Card");
                    }
                    return obj
                case "blank":
                    obj.content = draggedObject.data.content
                    obj.imgUrl = draggedObject.data.imgUrl
                    obj.type = "blank"
                    obj.status = draggedObject.data.status
                    obj.flip = draggedObject.data.flip
                    obj.label = ["Blank"]
                    obj.category = draggedObject.data.category
                    obj.submitted = draggedObject.data.status === "completed" ? true : false
                    
                    // Emitted to gamePlayerHand
                    if(draggedObject.data.blank !== 1 && draggedObject?.from === "InHand"){
                        EE.emit("Game Remove Card");
                    }
                    return obj
                case "stack":
                    obj.type = "stack"
                    obj.status = null
                    obj.label = ["Stack"]
                    return obj
                default:
                    return false;
            }
        }
        else{
            alert("You cannot place this card at the moment!")
            return false
        }
    },

    /**
     * Handler to check adding multiple cards at once
     * @param {object} clientCoordinates where user has dropped the card at x and y
     * @param {object} canvasData  user's coordinate and scale for their canvas
     * @param {object} cardAddition user's permission to add certain cards
     * @param {[object]} listOfObjs list of objects to be put into the canvas
     */
    checkMultiObjDrop(clientCoordinates, canvasData, cardAddition, listOfObjs){
        const {clientX, clientY} = clientCoordinates
        const {x, y, scale, latestID} = canvasData
        const uid = getUserId()
        let objs = []
        let counter = 0

        for(let i = 0; i < listOfObjs.length; i++){
            let card = listOfObjs[i]

            if(cardAddition && (cardAddition.hasOwnProperty(card.category) || cardAddition.hasOwnProperty("Any"))){
                let newID = Number(latestID) + Number(counter)
                counter++

                let obj = {
                    objId: `${uid}-${newID}`,
                    attachment: null,
                    interrupt: null,
                    origin: {
                        username: getUsername(),
                        id: uid,
                    },
                    flip: false,
                    edited:false, 
                    pin:false,
                    vote: false,
                    time: moment.utc(),
                    type: null,
                    status: null,
                    label: null,
                    category: null,
                    content: null,
                    imgUrl: null, 
                    pos: {
                        // Offset by 64 because of Top panel (4rem = 64px)
                        x: (-x + clientX + (i % 2 ? -1 * 10 * (i + 1) : 10 * (i + 1))) * (1/scale),
                        y: (-y + (clientY-64)+ (i % 2 ? -1 * 10 * (i + 1) : 10 * (i + 1))) * (1/scale)
                    },
                    anchorEl: null,
                    curlibID: card.libID,
                    pairDir: null,
                    paired: {
                      left: null,
                      right: null,
                      up: null,
                      down: null
                    },
                }

                switch(card.type){
                    case "predefined":
                    //Since we can now directly get the content of the cards via data.value when dragging
                    obj.content =  card.content
                    obj.type = "predefined"
                    obj.status = null
                    obj.flip = card.flip
                    obj.label = card.label
                    obj.category = card.category
                    obj.imgUrl = card.imgUrl
                    objs.push(obj)
                    break
                case "blank":
                    obj.content = card.content
                    obj.type = "blank"
                    obj.status = card?.status
                    obj.flip = card.flip
                    obj.label = ["Blank"]
                    obj.category = card.category
                    obj.submitted = card?.status === "completed" ? true : false
                    
                    objs.push(obj)
                    break
                default:
                    return false;
                }
            }
        }

        return objs
    },

    keyActionPerformed(shiftAction, shiftTo, canvasState){
        console.log(shiftAction,shiftTo)
        let updatedPlayerList = null, updatedPermission = null, newPlayerTurn = null, endOfPhase = false, newCurrentTurnStruct = null, newCurrentPhase = null
        let message = {
            playerNames: "",
            yamlMessage: "",
        }
    
        const { gameConfig, currentTurnStruct, playerList, playerTurn, playerID} = canvasState
    
        switch(shiftAction){
            case "Next Step":
                updatedPlayerList = iterateStep(currentTurnStruct, playerList, playerID, shiftTo)
                updatedPermission = returnPermissions(getUserId(), updatedPlayerList)
                break
            case "Next Player":
                EE.emit("Game Normal Change Player")
                break
            case "Next Turn Structure":
                newCurrentTurnStruct = shiftTo
            
                updatedPlayerList = setRoles(newCurrentTurnStruct, playerTurn, playerList)
                updatedPlayerList = setStep(newCurrentTurnStruct, updatedPlayerList)
                updatedPermission = returnPermissions(getUserId(), updatedPlayerList)
                break
            case "Next Phase":
                endOfPhase = true
                newPlayerTurn = 0
                newCurrentPhase = shiftTo
                newCurrentTurnStruct =  gameConfig[newCurrentPhase]["startingTurnStruct"]
    
                updatedPlayerList = setRoles(newCurrentTurnStruct, 0, playerList)
                updatedPlayerList = setStep(newCurrentTurnStruct, updatedPlayerList)
                updatedPermission = returnPermissions(getUserId(), updatedPlayerList) 
    
                //End of phase message
                // message = {
                //     playerNames: [getUsername()],
                //     yamlMessage: gameConfig[yamlPhase.currentPhase]["endMessage"]
                // }
                break
            case "Card Owner":
                EE.emit("Game Target Change Player")
            default:
                break
        }
        return {
            updatedPlayerList,
            updatedPermission,
            newPlayerTurn,
            newCurrentPhase,
            newCurrentTurnStruct,
            endOfPhase,
            message,
        }
    },

    getGameConfig(canvasData, playerTurn, participants, jamStatus){
        CanvasState.gameConfig = canvasData.gameConfig
        
        let tppData = canvasData?.tppData
        let gameConfigData ={
            currentTurnStruct: null,
            currentPhase: null,
            playerList: null,
            permissionsConfig: null,
            gameConfig: null
        }
    
        gameConfigData.currentPhase = tppData?.currentPhase ? 
                                        tppData.currentPhase : 
                                        canvasData.gameConfig["phaseConfig"]["startingPhase"]

        gameConfigData.currentTurnStruct = tppData?.currentTurnStruct ? 
                                            tppData.currentTurnStruct :
                                            canvasData.gameConfig[gameConfigData.currentPhase]["startingTurnStruct"]
                                            
        gameConfigData.gameConfig = CanvasState.gameConfig

        //When game inits, generate a playerList and the player's permission
        // ** Need a way to inject persistent score to player ROLES
        gameConfigData.playerList = setRoles(gameConfigData.currentTurnStruct, playerTurn, participants)
        //After generating a playerList, take the same playerList to generate score
        gameConfigData.playerList = getScore(false, gameConfigData.playerList)
        //Generate the current and next step for the player
        gameConfigData.playerList = tppData?.playerPermissions ? 
                                        loadStep(tppData.playerPermissions, gameConfigData.currentTurnStruct, gameConfigData.playerList) : 
                                        setStep(gameConfigData.currentTurnStruct, gameConfigData.playerList)
        //Returns the individual permissions config from the YAML
        gameConfigData.permissionsConfig = returnPermissions(getUserId(), gameConfigData.playerList)

        if(jamStatus !== "started"){
            let permissionsConfig = gameConfigData.permissionsConfig

            for(let settings in permissionsConfig.cardConfig){
                if(settings === "edittable"){
                    permissionsConfig.cardConfig[settings] = true
                }
                else{
                    permissionsConfig.cardConfig[settings].push("Any")
                }
            }

            permissionsConfig.cardAddition = {
                "Any":{
                    canvas: true,
                    inHand: true
                }
            }
        }
      
        return gameConfigData
    },

    checkCardStackAndPair(index, scale, canvasCoordinates, category, pairing, stacking){
        //Stop unecessary calculation if neither are settings are enabled
        const canPair = pairing.includes("Any") || pairing.includes(category)
        const canStack = stacking.includes("Any") || stacking.includes(category)

        if(canPair || canStack){
            const {canvasX, canvasY} = canvasCoordinates
            const userDraggedObject = ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).getBoundingClientRect()
        
            let nearObjs = []
        
            //Performs a check if other objects are near the dragged object
            //If yes then push it into nearObjs[] to check for collision
            CanvasState.objRefs.forEach((ref, key)=>{
                //In a strict comparision, null !== undefined but null == undefined
                if(ref != null && key !== index){
                    const rect2 = ReactDOM.findDOMNode(ref).getBoundingClientRect()
                    // console.log(rect2.y, userDraggedObject.y, scale)
                    // console.log(CanvasState.objRefMethods.get(key))
                    if(CanvasState.objRefMethods.get(key).state.type === "stack"){
                        if(userDraggedObject.y < rect2.y){
                            //console.log("above")
                            if(Math.abs(userDraggedObject.x - rect2.x) < (300 * scale) && Math.abs(userDraggedObject.y - rect2.y) < (500 * scale)){
                                if(!nearObjs.includes(key)){
                                    nearObjs.push(key)
                            }
                            }
                            else{
                                if(nearObjs.includes(key)){
                                    nearObjs.splice(key, 1)
                                }
                                CanvasState.objRefMethods.get(index).willNotSnap()
                                CanvasState.objRefMethods.get(index).willNotStack()
                                
                            }
                        }else{
                            //console.log("under")
                            if(Math.abs(userDraggedObject.x - rect2.x) < ((300 * scale) + rect2.height) && Math.abs(userDraggedObject.y - rect2.y) < ((500*scale) + rect2.height)){
                                if(!nearObjs.includes(key)){
                                    nearObjs.push(key)
                            }
                            }
                            else{
                                if(nearObjs.includes(key)){
                                    nearObjs.splice(key, 1)
                                }
                                CanvasState.objRefMethods.get(index).willNotSnap()
                                CanvasState.objRefMethods.get(index).willNotStack()
                                
                            }
                        }

                    }else{
                        if(Math.abs(userDraggedObject.x - rect2.x) < (300 * scale) && Math.abs(userDraggedObject.y - rect2.y) < (500 * scale)){
                            if(!nearObjs.includes(key)){
                                nearObjs.push(key)
                            }
                        }
                        else{
                            if(nearObjs.includes(key)){
                                nearObjs.splice(key, 1)
                            }
                            ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.border = "0px solid transparent"
            
                            CanvasState.objRefMethods.get(index).willNotSnap()
                            CanvasState.objRefMethods.get(index).willNotStack()
                            EE.emit("Unpair Event",{objId:key})
                        }
                    }
                }
            })

            // console.log(nearObjs);
            
            if(nearObjs.length !== 0){
                //console.log("near")
                for(let i = 0; i<nearObjs.length; i++){
                    const rect2 = ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).getBoundingClientRect()

                    //* Relative to the view of the non dragged object
                    //Comparision doesn't work for these kinds of listener, thus the algo goes like this
                    //If the differences of coordinates between the objects and the threshold is < 0.7% 
                    //and the x/y value is within the value of 15px threshold each of the cards, then pair
                    if(canPair){
                        let isHorizontalRightPairing = false
                        let isHorizontalLeftPairing = false
                        let isVerticalBottomPairing = false
                        let isVerticalTopPairing = false

                        isHorizontalRightPairing = 
                            (((Math.abs((rect2.x + rect2.width) - userDraggedObject.x) / (((rect2.x  + rect2.width) + userDraggedObject.x) / 2)) * 100) < 0.7 )
                            &&
                            ((userDraggedObject.y + userDraggedObject.height) / 2 > (rect2.y + rect2.height) / 2 - 15
                            &&
                            (userDraggedObject.y + userDraggedObject.height) / 2 < (rect2.y + rect2.height) / 2 + 15) 
        
                        isHorizontalLeftPairing =
                            (((Math.abs((userDraggedObject.x + userDraggedObject.width) - rect2.x) / (rect2.x + (userDraggedObject.x + userDraggedObject.width) / 2)) * 100) < 0.7 )
                            &&
                            ((userDraggedObject.y + userDraggedObject.height) / 2 > (rect2.y + rect2.height) / 2 - 15
                            &&
                            (userDraggedObject.y + userDraggedObject.height) / 2 < (rect2.y + rect2.height) / 2 + 15) 
            
                        isVerticalBottomPairing = 
                            (((Math.abs((rect2.y + rect2.height) - userDraggedObject.y) / (((rect2.y + rect2.height) + userDraggedObject.y)/ 2)) * 100) < 1 )
                            &&
                            ((userDraggedObject.x + userDraggedObject.width) / 2 - 15 < (rect2.x + rect2.width) / 2
                            &&
                            (userDraggedObject.x + userDraggedObject.width) / 2 > (rect2.x + rect2.width) / 2 - 15)
            
                        isVerticalTopPairing =
                            (((Math.abs((userDraggedObject.y + userDraggedObject.height) - rect2.y) / ((rect2.y + (userDraggedObject.y + userDraggedObject.height)) / 2)) * 100) < 1)
                            &&
                            ((userDraggedObject.x + userDraggedObject.width) / 2 - 15 < (rect2.x + rect2.width) / 2
                            &&
                            (userDraggedObject.x + userDraggedObject.width) / 2 > (rect2.x + rect2.width) / 2 - 15)

                        /// Right Pairing Handler
                        if(isHorizontalRightPairing){
                            //ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.borderLeft = "5px solid #FFAA0D"
                            //ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderRight = "5px solid #FFAA0D"

                            EE.emit("Pair Event",{objId:nearObjs[i], dir:"right"})
            
                            let x = (-canvasX + (rect2.x + rect2.width - 10)) * (1/scale);
                            let y =(-canvasY + (rect2.y - 112)) * (1/scale);
            
                            let snapTo = {
                                to:nearObjs[i],
                                dir: "right"
                            }
            
                            CanvasState.objRefMethods.get(index).willSnap({x, y},snapTo)
                        }
                        else{
                            // ReactDOM.findDOMNode(CanvasState.objRefs[index]).style.borderLeft = "0px solid transparent"
                            //ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderRight = "0px solid transparent"
                            //EE.emit("Unpair Event",{objId:nearObjs[i]})
                        }
            
                        /// Left Pairing Handler
                        if(isHorizontalLeftPairing){
                            //ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.borderRight = "5px solid #FFAA0D"
                            //ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderLeft = "5px solid #FFAA0D"

                            EE.emit("Pair Event",{objId:nearObjs[i], dir:"left"})
            
                            let x = (-canvasX+ (rect2.x - rect2.width + 10)) * (1/scale);
                            let y =(-canvasY + (rect2.y - 112)) * (1/scale);

                            let snapTo = {
                                to:nearObjs[i],
                                dir: "left"
                            }
            
                            CanvasState.objRefMethods.get(index).willSnap({x, y},snapTo)
                        }
                        else{
                            // ReactDOM.findDOMNode(CanvasState.objRefs[index]).style.borderRight = "0px solid transparent"
                            // ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderLeft = "0px solid transparent"
                           // EE.emit("Unpair Event",{objId:nearObjs[i]})
                        }
            
                        /// Bottom Pairing Handler
                        if(isVerticalBottomPairing){
                           // ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.borderTop = "5px solid #FFAA0D"
                           // ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderBottom = "5px solid #FFAA0D"

                            EE.emit("Pair Event",{objId:nearObjs[i], dir:"down"})
            
                            let x = (-canvasX+ (rect2.x)) * (1/scale);
                            let y =(-canvasY + (rect2.y - 112 + rect2.height - 10)) * (1/scale);
                            if(CanvasState.objRefMethods.get(index).state.category === "Stack"){
                                CanvasState.objRefMethods.get(index).willSnap({x, y})
                            }
                            else{
                                let snapTo = {
                                    to:nearObjs[i],
                                    dir: "down"
                                }
                
                                CanvasState.objRefMethods.get(index).willSnap({x, y},snapTo)
                            }
                            
                        }
                        else{
                            // ReactDOM.findDOMNode(CanvasState.objRefs[index]).style.border = "0px solid transparent"
                            // ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderBottom = "0px solid transparent"
                           // EE.emit("Unpair Event",{objId:nearObjs[i]})
                        }
            
            
                        /// Top Pairing Handler
                        if(isVerticalTopPairing){
                           // ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.borderBottom = "5px solid #FFAA0D"
                           // ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderTop = "5px solid #FFAA0D"
            
                            EE.emit("Pair Event",{objId:nearObjs[i], dir:"up"})

                            let x = (-canvasX+ (rect2.x)) * (1/scale);
                            let y =(-canvasY + (rect2.y - 112 - rect2.height + 10)) * (1/scale);
                            
                            //This is goddamn stupid, the reason why this is needed is because the stacked card have varied height based on the number of cards
                            //and thus the x & y doesn't really align when snapping though x & y is accurate when dragging around?? 
                            //AND THERE'S ONLY PROBLEM FOR TOP PAIRING???
                            if(CanvasState.objRefMethods.get(index).state.category === "Stack" && CanvasState.objRefMethods.get(nearObjs[i]).state.category !== "Stack"){
                                CanvasState.objRefMethods.get(index).willSnap({x, y: (y - ((CanvasState.objRefMethods.get(index).state.objs.length - 1) * 70))})
                            }
                            else if(CanvasState.objRefMethods.get(index).state.category !== "Stack" && CanvasState.objRefMethods.get(nearObjs[i]).state.category === "Stack"){
                                CanvasState.objRefMethods.get(index).willSnap({x, y: (y + ((CanvasState.objRefMethods.get(nearObjs[i]).state.objs.length - 1) * 70))})
                            }
                            else if(CanvasState.objRefMethods.get(index).state.category === "Stack" && CanvasState.objRefMethods.get(nearObjs[i]).state.category === "Stack"){
                                let length = CanvasState.objRefMethods.get(index).state.objs.length - CanvasState.objRefMethods.get(nearObjs[i]).state.objs.length
                                CanvasState.objRefMethods.get(index).willSnap({x, y: (y - (length * 70))})
                            }
                            else{
                                let snapTo = {
                                    to:nearObjs[i],
                                    dir: "up"
                                }
                
                                CanvasState.objRefMethods.get(index).willSnap({x, y},snapTo)
                            }
                        }
                        else{
                            // ReactDOM.findDOMNode(CanvasState.objRefs[index]).style.border = "0px solid transparent"
                            //  ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.borderTop = "0px solid transparent"
                            // EE.emit("Unpair Event",{objId:nearObjs[i]})
                        }
                    }
                    
                   if(canStack){
                
                        let isHorizontalStacking = 
                            userDraggedObject.x + userDraggedObject.width/2 < rect2.x + rect2.width
                            && 
                            userDraggedObject.x + userDraggedObject.width > rect2.x + rect2.width/2;
            
                        let isVerticalStacking = 
                            userDraggedObject.y + (userDraggedObject.height*(rect2.height/userDraggedObject.height))/2 < rect2.y + rect2.height
                            && 
                            userDraggedObject.y + userDraggedObject.height*(rect2.height/userDraggedObject.height) > rect2.y + rect2.height;
                        let isStacking = isHorizontalStacking && isVerticalStacking;


                        if(isStacking){
                            ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).style.border = "5px solid #FFAA0D"
                            ReactDOM.findDOMNode(CanvasState.objRefs.get(nearObjs[i])).style.border = "5px solid #FFAA0D"

                            CanvasState.objRefMethods.get(index).willStack(CanvasState.objRefMethods.get(index).state, CanvasState.objRefMethods.get(nearObjs[i]).state)
                        }
                        else{
                            // ReactDOM.findDOMNode(CanvasState.objRefs[nearObjs[i]]).style.border = "0px solid transparent"
                            // ReactDOM.findDOMNode(CanvasState.objRefs[index]).style.border = "0px solid transparent"
                            CanvasState.objRefMethods.get(index).willNotStack()
                        }
                    }
                }
            }
        }
        else{
            return
        }
    },

    getCardSize(index){
        const selectedCard = ReactDOM.findDOMNode(CanvasState.objRefs.get(index)).getBoundingClientRect()

        return {x: selectedCard.x, y: selectedCard.y}
    },
    
    isEmpty(arr){
        if(arr.length === 0){
            return true
        }
        return false;
    },

    isTurn(playerList, playerTurn){
        let uid = getUserId();
        let n = playerList.findIndex((n)=>{return n.uid === uid})
        if(n === playerTurn){
            return true
        }else{
            return false
        }
    },

    /**
     * Returns a list of objects that overlaps the selection box 
     * to be setState in gameCanvas
     * @param {[Number]} origin start target of selection box [x, y]
     * @param {[Number]} target end target of selection box [x, y]
     */
    checkSelectionOverlap(origin, target){
        let listOfOverlapObjects = []
        let listOfOverlapLibObj = []

        CanvasState.objRefs.forEach((ref, cardID) =>{
            if(ref != null){
                const cardObj = ReactDOM.findDOMNode(ref).getBoundingClientRect()

                let dragDirection = origin[0] < target[0] ? "right" : 'left'
                
                //I know this if else is a little pointless and the code below is repeated to some extent
                // but at least there's context to the if-else and the code
                if(dragDirection === 'right'){
                    let xOverlap = origin[0] < cardObj.x + cardObj.width/2 && cardObj.x + cardObj.width/2 < target[0]
                    //To account for users dragging the box up or down
                    let yOverlap = (origin[1] < cardObj.y + cardObj.height/2 && cardObj.y + cardObj.height/2 < target[1]) ||
                                    (target[1] < cardObj.y + cardObj.height/2 && cardObj.y + cardObj.height/2 < origin[1])

                    if(xOverlap && yOverlap){
                        listOfOverlapLibObj.push(CanvasState.objRefMethods.get(cardID).props.obj.libID)
                        listOfOverlapObjects.push(cardID)
                    }
                }
                else if(dragDirection === 'left'){
                    let xOverlap = target[0] < cardObj.x + cardObj.width/2 && cardObj.x + cardObj.width/2 < origin[0]
                    //To account for users dragging the box up or down
                    let yOverlap = (origin[1] < cardObj.y + cardObj.height/2 && cardObj.y + cardObj.height/2 < target[1]) ||
                                    (target[1] < cardObj.y + cardObj.height/2 && cardObj.y + cardObj.height/2 < origin[1])

                    if(xOverlap && yOverlap){
                        listOfOverlapLibObj.push(CanvasState.objRefMethods.get(cardID).props.obj.libID)
                        listOfOverlapObjects.push(cardID)
                    }
                }
            }
        })

        return {listOfOverlapObjects, listOfOverlapLibObj}
    },
    
    mutateGame(general,objects,latestObject, playerTurn, phase, turn, latestID, gid){
        // const {objects,latestObject, playerTurn, phase, turn, latestID} = this.state
    
        let gameData = {
            general,
            objects,
            latestObject,
            playerTurn,
            phase,
            turn,
            latestID
        }
    
       return clientURI.mutate({
           mutation: GAME_UPDATE_BY_ID,
           variables: {
               _id: gid,
               data: gameData
           }
       })
    },

    mutateGameObjects(objects, gid){
        return clientURI.mutate({
            mutation: GAME_UPDATE_OBJECTS,
            variables:{
                _id: gid,
                objs: objects
            }
        })
    },

    mutateParticipants(newParticipants,gid){
        return clientURI.mutate({
            mutation: GAME_UPDATE_PARTICIPANTS,
            variables: {
                _id: gid,
                participants: newParticipants
            }
        },(e)=>{console.log(e)})
    },
    
    mutateTPPData(currentPhase, currentTurnStruct, playerList, gid, status){
        if(status === "started"){
            //Stores the current phase & turn struct data into db
            let tppData ={
                currentPhase: currentPhase,
                currentTurnStruct: currentTurnStruct,
                playerPermissions: {}
            }

            for(let i=0; i<playerList.length; i++){
                let player = playerList[i]
                tppData.playerPermissions[i] = {
                    playerUID: player["uid"],
                    playerUName: player["username"],
                    currentStep: player["currentStep"],
                    score: player["score"],
                }
            }

            return clientURI.mutate({
                mutation: GAME_UPDATE_TPP,
                variables:{
                    _id: gid,
                    tppData: tppData
                }
            })
        }
    }, 

    mutateCanvasData(gid, canvasData){
        return clientURI.mutate({
            mutation: GAME_UPDATE_CANVASDATA,
            variables:{
                _id: gid,
                canvasData: canvasData
            }
        })
    }, 

    mutateCategoryAndLabelData(gid, listOfCategories, listOfLabels){
        return clientURI.mutate({
            mutation: GAME_UPDATE_LABEL_AND_CATEGORY,
            variables:{
                _id : gid,
                listOfCategories : listOfCategories,
                listOfLabels : listOfLabels
            }
        })
    }, 

    mutateCategoryColor(gid, categoryColorMap){
        return clientURI.mutate({
            mutation: GAME_UPDATE_CATEGORYCOLOR,
            variables:{
                _id: gid,
                categoryColorMap: categoryColorMap
            }
        })
    },

    mutateLibraryObjects(gid, libraryObjects){
        return clientURI.mutate({
            mutation: GAME_UPDATE_LIBRARYOBJECTS,
            variables:{
                _id: gid,
                libraryObjects: libraryObjects
            }
        })
    },

    mutateRulebook(gid, rulebook){
        return clientURI.mutate({
            mutation: GAME_UPDATE_RULEBOOK,
            variables:{
                _id: gid,
                rulebook: rulebook
            }
        })
    },

    mutateTimer(gid, timer){
        return clientURI.mutate({
            mutation: GAME_UPDATE_TIMER,
            variables:{
                _id: gid,
                timer: timer
            }
        })
    },

    mutateClearUnreceived(gid,uid){
        console.log("CLEAR")
        return clientURI.mutate({
            mutation: GAME_CLEAR_PLAYER_UNRECEIVED,
            variables: {
                gid,
                uid
            }
        })
    },

    mutateNotificationLog(gid,uid,notification){
        return clientURI.mutate({
            mutation: GAME_UPDATE_NOTIFICATION_LOG,
            variables: {
                gid,
                uid,
                notification
            }
        })
    }
}
